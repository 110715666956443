import React from "react"
import { HeadFC, Link } from "gatsby"
import { BaseLayout } from "@layouts";
import { SEO } from "@components";


const NotFoundPage = () => {
    return (
        <BaseLayout>
            <h1>Page not found</h1>
            <article className={ "section text-center" }>
                <p>
                    Sorry 😔, we couldn’t find what you were looking for.
                    <Link to="/">Go home</Link>.
                </p>
            </article>

        </BaseLayout>
    )
}

export default NotFoundPage

export const Head: HeadFC = ({location}) =>
    <SEO
        title={ "Not Found" }
        pathname={ location.pathname }
    />
